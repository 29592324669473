import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

export default function Post({
  id,
  title,
  body,
  photo,
  updated_at,
  facebook_medias,
}) {
  const url = useMemo(() => {
    return `/post/${id}`;
  }, [id]);

  const imageUrl = useMemo(() => {
    if (photo && photo.length > 0) {
      return `${process.env.REACT_APP_API_URL}${photo[0].url}`;
    }
    return facebook_medias[0]?.url;
  }, []);

  const dateStr = useMemo(() => {
    if (!updated_at) {
      return "";
    }
    const dt = new Date(updated_at);
    const year = dt.getFullYear();
    const month = dt.getMonth() + 1;
    const day = dt.getDate();
    return `${year}.${month}.${day}`;
  }, [updated_at]);

  return (
    <div className="blog-post card mb-3">
      {imageUrl ? (
        <img
          className="card-img-top blog-post-img"
          src={imageUrl}
          alt="Card image cap"
        />
      ) : (
        <img
          className="card-img-top blog-post-img"
          src="/assets/images/unavailable-image.png"
          alt="Card image cap"
        />
      )}
      <div className="card-body">
        <h6
          className="text-dark"
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {title}
        </h6>
        <p
          className="text-dark"
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
          }}
        >
          {body}
        </p>
        <a href={url} className="btn btn-success blog-post__btn">
          Дэлгэрэнгүй
        </a>
        <p className="blog-post__date" style={{ marginTop: "0.5rem" }}>
          {dateStr}
        </p>
      </div>
    </div>
  );
}
