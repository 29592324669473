import React, { useState, Component } from "react";
import ReactCountryFlag from "react-country-flag";
import DarkModeToggle from "react-dark-mode-toggle";
import $ from "jquery";
import "../assets/js/main.js";

class Header extends Component {
  constructor(props) {
    super(props);

    const themeCache = localStorage.getItem("theme");
    this.state = {
      theme: themeCache || "light",
      error: null,
      isLoaded: false,
      items: [],
      isUser: localStorage.getItem("name"),
    };

    this.toggleTheme = this.toggleTheme.bind(this);
    this.handleNav = this.handleNav.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    fetch(`${process.env.REACT_APP_API_URL}/exchange-rates`)
    // fetch(`http://localhost:1338/exchange-rates`)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
    );

    var raw_data = JSON.stringify({'jsonrpc':'2.0','params':{'db':'mfinance','login':'test','password':'0000'}})
    
    $.ajax({
      url: "https://erp.mfinance.mn/web/session/authenticate",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      data: raw_data,
      success: function(response) {
        if (localStorage.getItem("partner_id") == null){
          localStorage.setItem("partner_id", response.result.partner_id);
        }
        document.cookie = "session_id=" + response.result.session_id + ";";
      },
      error: function(response){
        console.log(response);
      }
    });
  }

  toggleTheme(e) {
    if (this.state.theme == "dark") {
      // go light
      document.documentElement.classList.remove("dark");
      this.setState({ theme: "light" });
      localStorage.setItem("theme", "light");
    } else {
      // go dark
      document.documentElement.classList.add("dark");
      this.setState({ theme: "dark" });
      localStorage.setItem("theme", "dark");
    }
  }

  handleNav(e) {
    document.getElementById("navbar").classList.toggle("navbar-mobile");
    document.getElementById("mobileNav").classList.toggle("bi-list");
    document.getElementById("mobileNav").classList.toggle("bi-x");
  }

  handleLogOut(e){
    localStorage.removeItem("name");
    localStorage.removeItem("partner_id");
    location.reload();
  }

  render() {
    const { error, isLoaded, items } = this.state;
    items.map((item) => {
      if (item.Code == "USD") {
        item.flag = "US";
      } else if (item.Code == "EUR") {
        item.flag = "EU";
      } else if (item.Code == "JPY") {
        item.flag = "JP";
      } else if (item.Code == "CHF") {
        item.flag = "CH";
      } else if (item.Code == "SEK") {
        item.flag = "SE";
      } else if (item.Code == "GBP") {
        item.flag = "GB";
      } else if (item.Code == "BGN") {
        item.flag = "BG";
      } else if (item.Code == "HUF") {
        item.flag = "HU";
      } else if (item.Code == "EGP") {
        item.flag = "EG";
      } else if (item.Code == "INR") {
        item.flag = "IN";
      } else if (item.Code == "HKD") {
        item.flag = "HK";
      } else if (item.Code == "RUB") {
        item.flag = "RU";
      } else if (item.Code == "KZT") {
        item.flag = "KZ";
      } else if (item.Code == "CNY") {
        item.flag = "CN";
      } else if (item.Code == "KRW") {
        item.flag = "KR";
      } else if (item.Code == "KPW") {
        item.flag = "KP";
      } else if (item.Code == "CAD") {
        item.flag = "CA";
      } else if (item.Code == "AUD") {
        item.flag = "AU";
      } else if (item.Code == "CZK") {
        item.flag = "CZ";
      } else if (item.Code == "TWD") {
        item.flag = "TW";
      } else if (item.Code == "THB") {
        item.flag = "TH";
      } else if (item.Code == "IDR") {
        item.flag = "ID";
      } else if (item.Code == "MYR") {
        item.flag = "MY";
      } else if (item.Code == "SGD") {
        item.flag = "SG";
      } else if (item.Code == "AED") {
        item.flag = "AE";
      } else if (item.Code == "KWD") {
        item.flag = "KW";
      } else if (item.Code == "NZD") {
        item.flag = "NZ";
      } else if (item.Code == "DKK") {
        item.flag = "DK";
      } else if (item.Code == "PLN") {
        item.flag = "PL";
      } else if (item.Code == "UAH") {
        item.flag = "UA";
      } else if (item.Code == "NOK") {
        item.flag = "NO";
      } else if (item.Code == "NPR") {
        item.flag = "NP";
      } else if (item.Code == "ZAR") {
        item.flag = "ZA";
      } else if (item.Code == "TRY") {
        item.flag = "TR";
      } else if (item.Code == "VND") {
        item.flag = "VN";
      } else if (item.Code == "XAU") {
        item.flag = "XU";
      } else if (item.Code == "XAG") {
        item.flag = "XG";
      } else if (item.Code == "SDR") {
        item.flag = "?";
      }
    });

    return (
      <header id="header" className="fixed-top" style={{ height: "80px" }}>
        <div className="container d-flex align-items-center">
          {/* <i
            className={
              this.state.theme === "light" ? "ri-sun-fill" : "ri-moon-fill"
            }
            style={{
              cursor: "pointer",
              fontSize: 28,
              marginRight: "2rem",
              color: this.state.theme === "light" ? "orange" : "#efefef",
            }}
            onClick={(e) => this.toggleTheme(e)}
          ></i> */}
          <div style={{ marginRight: "1rem" }}>
            <DarkModeToggle
              size={"3rem"}
              onChange={this.toggleTheme}
              checked={this.state.theme === "dark"}
            />
          </div>
          <h1 className="logo me-auto">
            <a href="/">
              <img
                src={
                  this.state.theme === "light"
                    ? "/assets/images/logo.png"
                    : "/assets/images/logo-white.png"
                }
                className="img-fluid"
                alt=""
                width="250"
                height="30"
              />
            </a>
          </h1>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li key="home">
                <a className="nav-link" href="/">
                  Нүүр хуудас
                </a>
              </li>
              <li key="service">
                <a
                  className="nav-link"
                  href="/#services"
                  onClick={(e) => {
                    e.preventDefault();
                    // animate
                    $("html, body").animate(
                      {
                        scrollTop: $("#services").offset().top - 150,
                      },
                      100
                    );
                  }}
                >
                  Үйлчилгээ
                </a>
              </li>
              <li key="about">
                <a className="nav-link" href="/#about-us">
                  Бидний тухай
                </a>
              </li>
              <li key="info">
                <a className="nav-link" href="/#info">
                  Мэдээ мэдээлэл
                </a>
              </li>
              <li key="contact">
                <a className="nav-link" href="/#contact">
                  Холбоо барих
                </a>
              </li>
              <li key="calc">
                <a className="nav-link" href="/loan-calculator">
                  Тооцоолуур
                </a>
              </li>
              {this.state.isUser ? (
                <li key="logout">
                  <a className="nav-link" href="#" onClick={this.handleLogOut}>
                    {this.state.isUser} : Гарах
                  </a>
                </li>
              ) : (
                <li key="login">
                  <a className="nav-link" href="/sign-in">
                    Нэвтрэх/Бүртгүүлэх
                  </a>
                </li>
              )}
            </ul>
            <i
              id="mobileNav"
              style={{
                marginLeft: "1rem",
                fontWeight: "bold",
              }}
              className="bi mobile-nav-toggle bi-list ml-1"
              onClick={this.handleNav}
            ></i>
          </nav>
          <div className="header-social-links d-flex">
            <a href="#" className="twitter">
              <i className="bu bi-twitter"></i>
            </a>
            <a href="https://www.facebook.com/millenniumbbsb"
              target="_blank"
              className="facebook"
            >
              <i className="bu bi-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bu bi-instagram"></i>
            </a>
          </div>
        </div>
        <br />
        <marquee
          width="100%"
          direction="left"
          scrollamount="10"
          height="22px"
          className="currency"
        >
          <ul style={{ whiteSpace: "nowrap", paddingBottom: "0px" }}>
            {items.map((item) => (
              <>
                <li key={item.Code.toString()} style={{ display: "inline" }}>
                  <ReactCountryFlag countryCode={item.flag} />
                  {item.Code + " "}
                  <strong>{item.Rate}</strong>{" "}
                </li>
              </>
            ))}
          </ul>
        </marquee>
      </header>
    );
  }
}
export default Header;
