import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import home from "./pages/home";
import service1 from "./pages/service-1";
import service2 from "./pages/service-2";
import service3 from "./pages/service-3";
import service4 from "./pages/service-4";
import service5 from "./pages/service-5";
import service6 from "./pages/service-6";
import post_single from "./pages/post_single";
import loanCalc from "./pages/loancalc";
import sign_in from "./pages/sign_in";
import sign_up from "./pages/sign_up";

import { ThemeProvider } from "./context/theme-context";

function App() {
  const [theme, setTheme] = useState("light");
  // theme side effect
  useLayoutEffect(() => {
    const themeCache = localStorage.getItem("theme") || "light";
    console.log(`theme cache ${themeCache}`);
    if (themeCache === "light") {
      // apply light
      document.documentElement.classList.remove("dark");
    } else {
      // apply dark
      document.documentElement.classList.add("dark");
    }
  }, [theme]);
  const toggleTheme = useCallback(() => {
    if (theme == "dark") {
      // go light
      setTheme("light");
    } else {
      // go dark
      setTheme("dark");
    }
  }, [theme]);

  return (
    <div className="wrapper">
      <ThemeProvider
        value={{ theme: theme, setTheme: setTheme, toggleTheme: toggleTheme }}
      >
        <Router>
          <Switch>
            <Route exact path="/" component={home} />
            <Route exact path="/post/:id" component={post_single} />
            <Route exact path="/service-1" component={service1} />
            <Route exact path="/service-2" component={service2} />
            <Route exact path="/service-3" component={service3} />
            <Route exact path="/service-4" component={service4} />
            <Route exact path="/service-5" component={service5} />
            <Route exact path="/service-6" component={service6} />
            <Route exact path="/loan-calculator" component={loanCalc} />
            <Route exact path="/sign-in" component={sign_in} />
            <Route exact path="/sign-up" component={sign_up} />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}
export default App;
