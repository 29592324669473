import React, { Component } from 'react';
import $ from "jquery";

import Header from '../components/header';
import Footer from '../components/footer';
import ClipLoader from 'react-spinners/ClipLoader';

class sign_up extends Component {    
    constructor(props){
        super(props);
        this.state = {
            login: '',
            password: '',
            name: '',
            confirmPassword: '',
            message: '',
            loading: false
        };

        this.signUpHandler = this.signUpHandler.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    }
    handleName(e) {
        this.setState({
            name: e.target.value,
        });
    }

    handleLogin(e) {
        this.setState({
            login: e.target.value,
        });
    }

    handlePassword(e) {
        this.setState({
            password: e.target.value
        });
    }
    handleConfirmPassword = (event) => {
        if (event.target.value !== this.state.password) {
            this.state.message = 'Нууц үг таарахгүй байна';
            this.setState({confirmPassword: event.target.value})
        }else{
            this.state.message = "";
            this.setState({confirmPassword: event.target.value})
        }
    }
    signUpHandler = (event) => {
        if(this.state.password !== this.state.confirmPassword){
            message.error("The passwords doesn't match")
            return false; // The form won't submit
        }else{
            let login = this.state.login;
            let password = this.state.password;
            let name = this.state.name;
            let db = "mfinance";
            event.preventDefault();
            this.setState({
                loading:true
            });
            $.ajax({
                url: "https://erp.mfinance.mn/website/signup",
                type: 'POST',
                data: {
                    'db': db,
                    'password': password,
                    'login': login,
                    'name': name
                },
                xhrFields: {
                    withCredentials: true
                },
                cache: false,
                success: function(res) {
                    try{
                        var response = JSON.parse(res);
                        console.log('success', response);
                        $.ajax({
                            url: "https://erp.mfinance.mn/website/login",
                            type: 'POST',
                            data: {
                              'db': response.db,
                              'password': response.password,
                              'login': response.login
                            },
                            xhrFields: {
                              withCredentials: true
                            },
                            cache: false,
                            success: function(data) {
                                try{
                                    var result = JSON.parse(data);
                                    console.log('success', result);
                                    document.cookie = result.session_id;
                                    localStorage.setItem("name", result.username);
                                    localStorage.setItem("partner_id", result.partner_id);
                                    window.location= "/";
                                    this.setState({
                                        loading:false
                                    });
                                }catch{
                                    alert(data);
                                    this.setState({
                                        loading:false
                                    });
                                }
                            }.bind(this),
                        });
                        this.setState({
                            loading:false
                        });
                    }catch{
                        alert(res);
                        this.setState({
                            loading:false
                        });
                    }
                }.bind(this),
                error: function(XMLHttpRequest, textStatus, errorThrown) { 
                    this.setState({
                        loading:false
                    });
                    alert("Амжилтгүй.")
                }.bind(this)
            });
        }
    }

    render() {
        const { loading } = this.state;
        const redColor = {
            color: "red !important"
        } 
        if (this.state.loading == true){
            return (
                <div>
                    <Header/>
                    <div className="container">
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <h1 style={{ paddingLeft: "30%" }} >Бүртгүүлэх:</h1>
                        <form style={{ width: "60%", paddingLeft: "30%" }} className="oe_signup_form" role="form" method="post" onSubmit={this.signUpHandler} id="signUpForm">
                            <div className="form-group field-login">
                                <label htmlFor="login" className="control-label">Таны эмэйл</label>
                                <input type="text" name="login" className="form-control" value={this.state.login} onChange={this.handleLogin} required="required"/>
                            </div>
                            <div className="form-group field-name">
                                <label htmlFor="name" className="control-label">Таны нэр</label>
                                <input type="text" name="name" value={this.state.name} onChange={this.handleName} className="form-control" required="required"/>
                            </div>

                            <div className="form-group field-password">
                                <label htmlFor="password" className="control-label">Нууц үг</label>
                                <input type="password" name="password" value={this.state.password} onChange={this.handlePassword} className="form-control" required="required"/>
                            </div>

                            <div className="form-group field-confirm_password">
                                <label htmlFor="confirm_password" className="control-label">Нууц үгийг баталгаажуулах</label>
                                <input type="password" name="confirm_password" value={this.state.confirmPassword} onChange={this.handleConfirmPassword} className="form-control" required="required"/>
                                <span style={redColor}>{this.state.message}</span>
                                <br/>
                            </div>

                            <div className="clearfix oe_login_buttons">
                                <a className="forgot text-muted" href="/sign-in">Дахин нэвтрэх</a>
                                <button type="submit" className="btn btn-success pull-left">Бүртгүүлэх</button>
                                <ClipLoader color={'#2daa8d'}/>
                            </div>
                        </form>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                    <Footer/>
                </div>
            )
        }else{
            return (
                <div>
                    <Header/>
                    <div className="container">
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <h1 style={{ paddingLeft: "30%" }} >Бүртгүүлэх:</h1>
                        <form style={{ width: "60%", paddingLeft: "30%" }} className="oe_signup_form" role="form" method="post" onSubmit={this.signUpHandler} id="signUpForm">
                            <div className="form-group field-login">
                                <label htmlFor="login" className="control-label">Таны эмэйл</label>
                                <input type="text" name="login" className="form-control" value={this.state.login} onChange={this.handleLogin} required="required"/>
                            </div>
                            <div className="form-group field-name">
                                <label htmlFor="name" className="control-label">Таны нэр</label>
                                <input type="text" name="name" value={this.state.name} onChange={this.handleName} className="form-control" required="required"/>
                            </div>
    
                            <div className="form-group field-password">
                                <label htmlFor="password" className="control-label">Нууц үг</label>
                                <input type="password" name="password" value={this.state.password} onChange={this.handlePassword} className="form-control" required="required"/>
                            </div>
    
                            <div className="form-group field-confirm_password">
                                <label htmlFor="confirm_password" className="control-label">Нууц үгийг баталгаажуулах</label>
                                <input type="password" name="confirm_password" value={this.state.confirmPassword} onChange={this.handleConfirmPassword} className="form-control" required="required"/>
                                <span style={redColor}>{this.state.message}</span>
                                <br/>
                            </div>
    
                            <div className="clearfix oe_login_buttons">
                                <a className="btn btn-link pull-right" href="/sign-in">Дахин нэвтрэх</a>
                                <button type="submit" className="btn btn-success pull-left">Бүртгүүлэх</button>
                            </div>
                        </form>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                    <Footer/>
                </div>
            )
        }
    }
}
export default sign_up;