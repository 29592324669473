import React, { Component } from 'react';
    
class Footer extends Component {
    render() {
        return (
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3>Миллениум Финанс</h3>
                                <h3>Millennium Finance</h3>
                                {/* <p>
                                    Анх 2009 оны 12 дугаар сард Танил Групп ББСБ ХХК
                                    нэртэйгээр Санхүүгийн зохицуулах хорооны №1/192 тоот 
                                    Тусгай зөвшөөрлийн гэрчилгээний дугаар бүхий 
                                    “Зээлийн үйл ажиллагаа“–г эрхлэхээр байгуулагдсан 
                                    ба 2017 оны 10 дугаар сараас “Миллениум финанс ББСБ” ХХК 
                                    нэртэйгээр үйл ажиллагаа үргэлжлүүлэн хүргэж байна.
                                </p> */}
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Цэс</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Нүүр хуудас</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Бидний тухай</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Үйлчилгээ</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Мэдээ мэдээлэл</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Холбоо барих</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Үйлчилгээ</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Лизингийн зээл</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Үл хөдлөх барьцаалсан зээл</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Тээврийн хэрэгсэл барьцаалсан зээл</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Дугаар барьцаалсан зээл</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Бизнесийн зээл</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Тусгай зээл</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Холбоо барих</h4>
                                <p>
                                    <strong>Хаяг:</strong> Улаанбаатар, Сүхбаатар дүүрэг, 1-р хороо, Гэрэгэ цамхаг 8-н давхар<br></br>
                                    <strong>Утас:</strong> +976 77235555<br></br>
                                    <strong>Имэйл:</strong> info@mfinance.mn<br></br>
                                </p>
                            </div>
                        </div>

                        <div className="container d-md-flex py-4">
                            <div className="me-md-auto text-center text-md-start">
                                <div className="copyright">
                                    &copy; Copyright <strong><span><a href="http://remif.mn">Remif Technologies LLC</a></span></strong>. All Rights Reserved
                                </div>
                            </div>
                            <div className="social-links text-center text-md-right pt-3 pt-md-0">
                                <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>   
            </footer>     
        )
    }
}

export default Footer;