import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loading = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <ClipLoader/>
  </div>
);

export default Loading;