import React, { Component } from "react";

import Header from "../components/header";
import Footer from "../components/footer";

class loanCalc extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          value: { min: 2, max: 10 },
        };
    }
    render(){
        function handleRange(e){
            document.getElementById("amountNumber").value = e.target.value;
        }

        function handleLengthRange(e){
            document.getElementById("lengthNumber").value = e.target.value;
        }
        function handleLengthNumber(e){
            document.getElementById("lengthRange").value = e.target.value;
        }

        function handleInterestRange(e){
            document.getElementById("interestNumber").value = e.target.value;
        }
        function handleInterestNumber(e){
            document.getElementById("interestRange").value = e.target.value;
        }

        function toCurrency(e) {
            const formatter = new Intl.NumberFormat();
            
            var a = Number((e.target.value).replace(/,/g, ''));
            
            document.getElementById("amountNumber").value = formatter.format(a);
            document.getElementById("amountRange").value = a;
        }

        function CalcD(intrate, monthcount){
            var days = [];
            for(var i = 1; i <= monthcount; i++){
                var temp = new Date();
                var date2 = new Date(temp.setMonth(temp.getMonth() + i));
                var temp1 = new Date();
                var date1 = new Date(temp1.setMonth(temp1.getMonth() + (i-1)));
    
                var diffTime = date2.getTime() - date1.getTime();
                var diffDay = diffTime / (1000 * 3600 * 24); 
                days.push([date2, Math.round(diffDay)])
            }
            return days;
        }

        function CalcI(intrate, monthcount, days) {
            var itg = 1, sum = 0, coefficient = 1;
            for (var i = 0; i < monthcount; i++) {
                coefficient = coefficient / (1 + days[i][1] * intrate / 100 / 365);
                sum += coefficient;
            }
            return sum;
        }

        function calc(e){
            e.preventDefault();
            document.getElementById("tableBody").innerHTML = '';

            var type = document.getElementById("payback").value;
            if(type == 'equalAll'){ // Niit tulbur tentsuu
                var amount = parseFloat(document.getElementById("amountNumber").value.replace(/,/g,'')).toFixed(2);
                var term = parseFloat(document.getElementById("lengthNumber").value);
                var intrate = parseFloat(document.getElementById("interestNumber").value).toFixed(2);

                if(amount == null || amount == '' || isNaN(amount) || term == null || term == '' || isNaN(term) || intrate == null || intrate == '' || isNaN(intrate)){
                    // Хоосон утга анхааруулах
                    return;
                }

                var days = CalcD(intrate, term);
                var tableData = [];

                var totalCoef =  CalcI(intrate, term, days);
                var principal = amount
                var totalMonthlyPayment = amount / totalCoef;
                var totalPayment = 0;

                for(var i = 0; i < term; i++){
                    var interestPayment = principal * days[i][1] * intrate / 100 / 365
                    var loanPayment = totalMonthlyPayment - interestPayment
                    principal -= loanPayment

                    tableData.push([principal, loanPayment, interestPayment, totalMonthlyPayment]);
                    totalPayment += totalMonthlyPayment;
                }
                var table = document.getElementById("tableBody")

                var row, numNonFormat;

                const formatter = new Intl.NumberFormat();

                document.getElementById("monthlyPayment").innerHTML = formatter.format(Number(parseFloat(totalMonthlyPayment).toFixed(2)));
                document.getElementById("totalPayment").innerHTML = formatter.format(Number(parseFloat(totalPayment).toFixed(2)));

                for(var i = 0; i < term; i++){
                    row = table.insertRow(i)

                    numNonFormat = Number(parseFloat(tableData[i][0]).toFixed(2));
                    tableData[i][0] = formatter.format(numNonFormat)

                    numNonFormat = Number(parseFloat(tableData[i][1]).toFixed(2));
                    tableData[i][1] = formatter.format(numNonFormat)

                    numNonFormat = Number(parseFloat(tableData[i][2]).toFixed(2));
                    tableData[i][2] = formatter.format(numNonFormat)

                    numNonFormat = Number(parseFloat(tableData[i][3]).toFixed(2));
                    tableData[i][3] = formatter.format(numNonFormat)

                    row.insertCell(0).innerHTML = (i+1);
                    row.insertCell(1).innerHTML = (days[i][0].getFullYear() + '/' + (days[i][0].getMonth() + 1) + '/' + days[i][0].getDate());
                    row.insertCell(2).innerHTML = (days[i][1]);
                    row.insertCell(3).innerHTML = (tableData[i][0]);
                    row.insertCell(4).innerHTML = (tableData[i][1]);
                    row.insertCell(5).innerHTML = (tableData[i][2]);
                    row.insertCell(6).innerHTML = (tableData[i][3]);
                }
            }else if(type =='equalPayment'){ // Undsen tulbur tentsuu
                var amount = parseFloat(document.getElementById("amountNumber").value.replace(/,/g,'')).toFixed(2);
                var term = parseFloat(document.getElementById("lengthNumber").value);
                var intrate = parseFloat(document.getElementById("interestNumber").value).toFixed(2);

                if(amount == null || amount == '' || isNaN(amount) || term == null || term == '' || isNaN(term) || intrate == null || intrate == '' || isNaN(intrate)){
                    // Хоосон утга анхааруулах
                    return;
                }

                var days = CalcD(intrate, term);
                var tableData = [];
                var totalPayment = 0;

                var principal = amount

                for(var i = 0; i < term; i++){
                    var interestPayment = principal * days[i][1] * intrate / 100 / 365
                    var loanPayment = amount/term + interestPayment
                    principal -= amount/term

                    tableData.push([principal, (amount/term), interestPayment, loanPayment]);
                    totalPayment += loanPayment;
                }
                var table = document.getElementById("tableBody")

                var row;

                const formatter = new Intl.NumberFormat();

                document.getElementById("monthlyPayment").innerHTML = formatter.format(Number(parseFloat(loanPayment).toFixed(2)));
                document.getElementById("totalPayment").innerHTML = formatter.format(Number(parseFloat(totalPayment).toFixed(2)));
                
                for(var i = 0; i < term; i++){
                    row = table.insertRow(i)
                    
                    
                    numNonFormat = Number(parseFloat(tableData[i][0]).toFixed(2));
                    tableData[i][0] = formatter.format(numNonFormat)

                    numNonFormat = Number(parseFloat(tableData[i][1]).toFixed(2));
                    tableData[i][1] = formatter.format(numNonFormat)

                    numNonFormat = Number(parseFloat(tableData[i][2]).toFixed(2));
                    tableData[i][2] = formatter.format(numNonFormat)

                    numNonFormat = Number(parseFloat(tableData[i][3]).toFixed(2));
                    tableData[i][3] = formatter.format(numNonFormat)

                    row.insertCell(0).innerHTML = (i+1);
                    row.insertCell(1).innerHTML = (days[i][0].getFullYear() + '/' + (days[i][0].getMonth() + 1) + '/' + days[i][0].getDate());
                    row.insertCell(2).innerHTML = (days[i][1]);
                    row.insertCell(3).innerHTML = (tableData[i][0]);
                    row.insertCell(4).innerHTML = (tableData[i][1]);
                    row.insertCell(5).innerHTML = (tableData[i][2]);
                    row.insertCell(6).innerHTML = (tableData[i][3]);
                }
            }
        }

        return (
            <>
                <Header />
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <h2>Зээлийн тооцоолуур:</h2>
                        <hr/>
                        <form className="form-control" action="#" style={{ border: 'none' }}>
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="row">
                                        <div className="row">
                                            <div className="input-group has-validation">
                                                <div className="col-6">
                                                    <label>Зээлийн хэмжээ:</label>
                                                </div>
                                                <div className="col-6">
                                                    <input 
                                                        type="text" 
                                                        id="amountNumber"
                                                        className="form-control"
                                                        placeholder="000,000,000 ₮"
                                                        maxLength="13"
                                                        onChange={ toCurrency }
                                                        style={{
                                                            borderStyle: 'solid',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <input 
                                                    type="range" 
                                                    min="0" 
                                                    max="500000000" 
                                                    defaultValue="0"
                                                    id="amountRange" 
                                                    onChange={ toCurrency }
                                                    style={{
                                                        width: '100%',
                                                        color: '#417f67',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="row">
                                            <div className="input-group has-validation">
                                                <div className="col-6">
                                                    <label>Зээлийн хугацаа(сараар):</label>
                                                </div>
                                                <div className="col-6">
                                                    <input 
                                                        type="number" 
                                                        id="lengthNumber"
                                                        placeholder="0"
                                                        max="360"
                                                        className="form-control"
                                                        onChange={ handleLengthNumber }
                                                        style={{ 
                                                            borderStyle: 'solid',
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <input 
                                                    type="range" 
                                                    min="0" 
                                                    max="360" 
                                                    id="lengthRange" 
                                                    defaultValue="0"
                                                    onChange={ handleLengthRange }
                                                    style={{
                                                        width: '100%',
                                                        color: '#417f67',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="row">
                                            <div className="input-group has-validation">
                                                <div className="col-6">
                                                    <label>Зээлийн хүү(жилээр):</label>
                                                </div>
                                                <div className="col-6">
                                                    <input 
                                                        type="number"
                                                        step="0.01" 
                                                        id="interestNumber"
                                                        placeholder="0.0%"
                                                        max="40"
                                                        className="form-control"
                                                        onChange={ handleInterestNumber }
                                                        style={{ 
                                                            borderStyle: 'solid',
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <input 
                                                    type="range" 
                                                    min="0" 
                                                    max="30"
                                                    step="0.01"
                                                    defaultValue="0"
                                                    id="interestRange" 
                                                    onChange={ handleInterestRange }
                                                    style={{
                                                        width: '100%',
                                                        color: '#417f67',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="row">
                                            <div className="input-group">
                                                <div className="col-6">
                                                    <label>Зээлийн эргэн төлөлт:</label>
                                                </div>
                                                <div className="col-6">
                                                    <select
                                                        id="payback" 
                                                        className="form-select"
                                                        style={{ 
                                                            borderStyle: 'solid',
                                                            fontSize: 'smaller'
                                                    }}>
                                                        <option value="equalPayment">Үндсэн төлбөр тэнцүү</option>
                                                        <option value="equalAll">Нийт төлбөр тэнцүү</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row" style={{ width: '97%', alignItems: 'center', paddingLeft: '3%'  }}>
                                        <button type="submit" className="btn btn-success" onClick={ calc }>Тооцоолох</button>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="row" style={{ height: '30%' }}>
                                        <div className="col-12 text-center">
                                            <div style={{
                                                height: '100%',
                                                verticalAlign: 'middle',
                                            }}>
                                                <span id="monthlyPayment"></span>
                                                <br/>
                                                <span >Сарын төлбөр</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row" style={{ height: '70%' }}>
                                        <div className="col-12 text-center" id="middle-text">
                                            <span id="totalPayment"></span>
                                            <br/>
                                            <span>Зээлийн хугацаанд төлөх нийт төлбөр</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </form>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <table className="table table-bordered table-striped table-sm">
                                <div className="table-responsive">
                                    <table className="table align-middle " id="loanTable">
                                        <thead id="calc-table-head">
                                            <tr>
                                                <th>Сар</th>
                                                <th>Огноо</th>
                                                <th>Хоног</th>
                                                <th>Зээлийн үлдэгдэл</th>
                                                <th>Зээлийн төлөлт</th>
                                                <th>Зээлийн хүү</th>
                                                <th>Нийт төлбөр</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tableBody">
                                        </tbody>
                                    </table>
                                </div>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <Footer />
            </>
        )
    }
}
export default loanCalc;