import React, { useEffect, useState } from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import Hero from "../components/hero";
import About from "../components/about";
import Services from "../components/services";
import Info from "../components/info";
import Contact from "../components/contact";

export default function home(props) {
  return (
    <div>
      <Header />
      <Hero />
      <Services />
      <About />
      <Info />
      <Contact />
      <Footer />
    </div>
  );
}
