import React, { Component } from 'react';
 
import Header from '../components/header';
import Footer from '../components/footer';
import Services from '../components/services';

class service1 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Лизингийн зээл:</h1>
                    <br></br>
                    <br></br>
                    <div className="page-padding post-99 page type-page status-publish hentry">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <h3>Автомашин лизингийн</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Урьдчилгаа төлбөр</strong></td>
                                            <td>30%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>30 сар</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү нь</strong></td>
                                            <td>2,8-3,5%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>1%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Даатгал</strong></td>
                                            <td>Зээлийн хугацаанд<br></br>даатгал хийх</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Барьцаа барьцаалж болно</strong></td>
                                            <td>Урьдчилгаа 0-30%-д<br></br>үл хөдлөх</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <h3>Хэрэглээний лизинг</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Зээлийн дүн</strong></td>
                                            <td>5,000,000 төгрөг</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>30 сар</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү нь</strong></td>
                                            <td>4%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>1%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Барьцаа барьцаалж болно</strong></td>
                                            <td>Урьдчилгаа 0-30%-д <br></br>үл хөдлөх</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                    <h3>Дугаарын лизинг</h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong>Зээлийн дүн</strong></td>
                                                <td>30%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Зээлийн хугацаа</strong></td>
                                                <td>30 сар</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Зээлийн хүү нь</strong></td>
                                                <td>3-3,5%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Зээлийн шимтгэл</strong></td>
                                                <td>1%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Барьцаа барьцаалж болно</strong></td>
                                                <td>Урьдчилгаа 0-30%-д үл<br></br>хөдлөх</td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div className="col-12">
                                <h1>Бүрдүүлэх бичиг баримт:</h1>
                                <br></br>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h3>Байгууллагын хувьд:</h3>
                                <ul>
                                    <li>Зээлийн өргөдөл, гүйцэтгэх захиралын зураг /ББСБ-ын маягтийн дагуу/</li>
                                    <li>ААН гэрчилгээ, дүрэм, тусгай зөвшөөрөл /Хуулбар/</li>
                                    <li>Гүйцэтгэх захирал цахим иргэний үнэмлэх</li>
                                    <li>Сүүлийн 1 жилийн татварын албаар баталгаажсан тайлан, тэнцэл</li>
                                    <li>Худалдан авах эд хөрөнгийн төлбөрийн нэхэмжлэх, гаалийн мэдүүлэгийн хуулбар</li>
                                    <li>Давхар зээлтэй бол зээлийн гэрээ, дансны хуулга</li>
                                    <li>Бизнесийн үйл ажиллагаатай холбоотой сүүлийн 6 сарын дансны хуулга ажил гүйцэтгэсэн болон гүйцэтгэх гэрээнүүд</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h3>Иргэний хувьд:</h3>
                                <ul>
                                    <li>Зээлийн өргөдөл, зураг /ББСБ-ын маягтийн дагуу/</li>
                                    <li>Иргэний үнэмлэх /Хуулбар/</li>
                                    <li>Давхар зээлтэй бол зээлийн гэрээ, Дансны хуулга</li>
                                    <li>Худалдан авах хөрөнгийн төлбөрийн нэхэмжлэх, барааны тодорхойлолт,</li>
                                    <li>харангийн үзүүлэлт, гаалийн мэдүүлэгийн хуулбар</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <h2>Бизнесийн тогтмол орлоготой бол:</h2>
                                <ul>
                                    <li>Бизнесийн үйл ажиллагаатай холбоотой банкан дэх сүүлийн 6 сарын дансны<br></br>
                                    хуулга, ажил гүйцэтгэсэн болон гүйцэтгэх гэрээнүүд
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <h2>Цалингийн тогтмол орлоготой бол:</h2>
                                <ul>
                                    <li>Цалингийн тодорхойлолт</li>
                                    <li>НД лавлагаа</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <Services/>
                <Footer/>
            </div>
        )
    }
}
export default service1;