import React, { Component } from 'react';
    
class About extends Component {
    render() {
        return (
            <section id="about-us" className="about-us" style={{ background: 'white',  backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%'}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="container" data-aos="fade-up">
                    <div className="row content">
                        <div className="col-lg-6" data-aos="fade-right">
                            <h2>Милленниум финанс ББСБ</h2>
                            <p>
                                Анх 2009 оны 12 дугаар сард ”Танил Групп ББСБ” ХХК
                                нэртэйгээр байгуулагдсан ба 2017 оны 10 дугаар сараас
                                “Милленниум финанс ББСБ” ХХК нэртэйгээр үйл ажиллагаа үргэлжлүүлэн хүргэж байна.
                            </p>
                            <p>
                                Өнгөрсөн хугацаанд Санхүүгийн Зохицуулах Хорооны шалгуур
                                үзүүлэлтүүдийг сар бүр биелүүлж, тогтвортой үйл ажиллагаа явуулан,
                                Монгол Улсад санхүүгийн бичил санхүүгийн салбарыг хөгжүүлэхэд хувь
                                нэмрээ оруулан, харилцагч нартаа бичил санхүүгийн үйлчилгээг
                                чанартай хүргэж ажиллахыг зорин ажиллаж байгаа болно.
                            </p>
                            <p>
                                Ийнхүү ажилласны үр дүнд өнөөдөр манай байгууллага харилцагчдынхаа
                                санхүүгийн бүхий л хэрэгцээнд тулгуурлан бичил бизнес болон жижиг
                                дунд бизнесийг дэмжих зээл, хэрэглээний бүх төрлийн зээлүүдийг
                                түргэн шуурхай хүргэж байна
                            </p>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                            <h2 style={{ height: '60px' }}></h2>
                            <p>
                                Бид үйл ажиллагааныхаа бүхий л төвшинд нийгмийн хариуцлагатай,
                                шударга, ёс суртахуунтай үзэл бодлыг баримтлан,
                                харилцагчийнхаа эрх ашгийг дээдэлсэн шинэлэг, 
                                хүртээмжтэй үйлчилгээгээр салбартаа тэргүүлэгч компани болно.
                            </p>
                            <ul>
                                <li key="service-1"><i className="ri-check-double-line"></i> Лизингийн зээл</li>
                                <li key="service-2"><i className="ri-check-double-line"></i> Үл хөдлөх барьцаалсан зээл</li>
                                <li key="service-3"><i className="ri-check-double-line"></i> Тээврийн хэрэгсэл барьцаалсан зээл</li>
                                <li key="service-4"><i className="ri-check-double-line"></i> Дугаар барьцаалсан зээл</li>
                                <li key="service-5"><i className="ri-check-double-line"></i> Бизнесийн зээл</li>
                                <li key="service-6"><i className="ri-check-double-line"></i> Тусгай зээл</li>
                            </ul>
                        </div>
                    </div><br/>
                    <div className="row content">
                        <div className="col-lg-6" data-aos="fade-right">
                            <h3><strong>Үнэт зүйлс</strong></h3>
                            <h6>- Мэргэшсэн мэргэжлийн өндөр чадвартай ажиллах боловсон хүчин</h6>
                            <h6>- Харилцагчдын хэрэгцээнд тулгуурласан шинэлэг, шуурхай үйлчилгээ</h6>
                            <h6>- Нийгмийн хариуцлага, зөв ёс суртахуун, шударга үзэл баримтлал</h6>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About;