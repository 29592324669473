import React, { Component } from 'react';
import $ from "jquery";

import Header from '../components/header';
import Footer from '../components/footer';
import ClipLoader from 'react-spinners/ClipLoader';

class sign_in extends Component {
    constructor(props){
        super(props);
        this.state = {
            login: '',
            password: '',
            loading: false
        };

        this.loginHandler = this.loginHandler.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }
    handleLogin(e) {
        this.setState({
            login: e.target.value,
        });
    }
    
    handlePassword(e) {
        this.setState({
            password: e.target.value
        });
    }
    loginHandler = (event) => {
        let login = event.target.login.value;
        let password = event.target.password.value;
        let db = "mfinance";
        event.preventDefault();
        this.setState({
            loading:true
        });
        $.ajax({
            url: "https://erp.mfinance.mn/website/login",
            type: 'POST',
            data: {
              'db': db,
              'password': password,
              'login': login
            },
            xhrFields: {
              withCredentials: true
            },
            cache: false,
            success: function(data) {
                try{
                    var result = JSON.parse(data);
                    console.log('success', result);
                    document.cookie = result.session_id;
                    localStorage.setItem("name", result.username);
                    localStorage.setItem("partner_id", result.partner_id);
                    window.location= "/";
                    this.setState({
                        loading:false
                    });
                }catch{
                    alert(data)
                    this.setState({
                        loading:false
                    });
                }
            }.bind(this),
            error: function(XMLHttpRequest, textStatus, errorThrown) { 
                this.setState({
                    loading:false
                });
                alert("Амжилтгүй.")
            }.bind(this)
        });
    }

    render() {
        const { loading } = this.state;
        if (this.state.loading == true){
            return (
                <div>
                    <Header/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <form className="box" role="form" method="post" onSubmit={this.loginHandler}>
                                        <h1>Нэвтрэх</h1>
                                        <p className="text-muted"> Нэвтрэх нэр нууц үгээ оруулна уу?</p>
                                        <input type="text" name="login" id="login" placeholder="Нэврэх нэр" value={this.state.login} onChange={this.handleLogin} required/> 
                                        <input type="password" name="password" id="password" placeholder="Нууц үг" maxLength="4096" value={this.state.password} onChange={this.handlePassword} required/>
                                        <a className="forgot text-muted" href="/sign-up">Бүртгүүлэх</a>
                                        <input type="submit" value="Нэвтрэх" href="#"/>
                                        <ClipLoader color={'#2daa8d'} css={ "background: black;" }/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )
        }else{
            return (
                <div>
                    <Header/>
                    <div className="container">
                        {/* <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/> */}
                        {/* <h1 style={{ paddingLeft: "30%" }}>Нэвтрэх</h1>
                        <form style={{ width: "60%", paddingLeft: "30%" }} role="form" method="post" onSubmit={this.loginHandler} id="loginForm">
                            <div className="form-group field-login">
                                <label htmlFor="login" className="control-label">Имэйл</label>
                                <input type="text" name="login" id="login" className="form-control" value={this.state.login} onChange={this.handleLogin} required/>
                            </div>
                            <div className="form-group field-password">
                                <label htmlFor="password" className="control-label">Нууц үг</label>
                                <input type="password" name="password" id="password" className="form-control" maxLength="4096" value={this.state.password} onChange={this.handlePassword} required/>
                            </div>
                            <br/>
                            <div className="clearfix oe_login_buttons">
                                <a className="btn btn-link pull-right" href="/sign-up">Бүртгүүлэх</a>
                                <button type="submit" className="btn btn-success">Нэвтрэх</button>
                            </div>
                        </form> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <form className="box" role="form" method="post" onSubmit={this.loginHandler}>
                                        <h1>Нэвтрэх</h1>
                                        <p className="text-muted"> Нэвтрэх нэр нууц үгээ оруулна уу?</p>
                                        <input type="text" name="login" id="login" placeholder="Нэврэх нэр" value={this.state.login} onChange={this.handleLogin} required/> 
                                        <input type="password" name="password" id="password" placeholder="Нууц үг" maxLength="4096" value={this.state.password} onChange={this.handlePassword} required/>
                                        <a className="forgot text-muted" href="/sign-up">Бүртгүүлэх</a>
                                        <input type="submit" value="Нэвтрэх" href="#"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )
        }
    }
}
export default sign_in;