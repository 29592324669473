import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import MarkDownIt from "markdown-it";
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from "../components/loading";
import Header from "../components/header";
import Footer from "../components/footer";

const md = new MarkDownIt();
const imgUrlRegex = new RegExp(/\/uploads/g);
export default function PostSingle() {
  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const history = useHistory();
  const [post, setPost] = useState(() => {
    if (posts) {
      return posts.filter((p) => p.id == id)[0];
    }
  });

  const imageUrl = useMemo(() => {
    if (post?.photo && post?.photo.length > 0) {
      return `${process.env.REACT_APP_API_URL}${post?.photo[0].url}`;
    }
    return post?.facebook_medias[0]?.url;
  }, [post?.facebook_medias]);

  const [loading, setLoading] = useState(false);
  // fetch хийгдсэн post бол postCache нь null биш
  const fetchPost = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/posts/${id}`
      );
      setPost(await response.json());
      setLoading(false);
    } catch (e) {
      history.push("/404");
    }
  };
  // social share link
  const url = useMemo(() => {
    return `/news/${id}`;
  }, [id]);
  const fullUrl = useMemo(() => {
    return `${url}`;
  }, [url]);
  // мэдээний body -г html болгох
  const newsBodyHtml = useMemo(() => {
    if (!post) {
      return "";
    }
    if(post.body.includes("<code>") && post.body.includes("</code>") ){
      var removeEmbedStart = post.body.indexOf("<code>");
      var removeEmbedEnd = post.body.indexOf("</code>");
      var removeEmbed = post.body.substring(removeEmbedStart, removeEmbedEnd+7);
      var returnValue = post.body.replace(removeEmbed, "");
      return md.render(
        returnValue.replace(imgUrlRegex, `${process.env.API_URL}/uploads`)
      );
    }
    return md.render(
      post.body.replace(imgUrlRegex, `${process.env.API_URL}/uploads`)
    );
  }, [post?.body]);
  // Embed code болгох
  const embedCodeHtml = useMemo(() => {
    if (!post) {
      return "";
    }
    if(post.body.includes("<code>") && post.body.includes("</code>") ){
      var getEmbedStart = post.body.indexOf("<code>");
      var getEmbedEnd = post.body.indexOf("</code>");
      var getEmbed = post.body.substring(getEmbedStart, getEmbedEnd+7);
      console.log(String(getEmbed));
      var returnValue = getEmbed.replace(getEmbedStart, "");
      returnValue = getEmbed.replace(getEmbedEnd,"")
      return String(returnValue)
    }
    return "";
  }, [post?.body]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // facebook images
  const facebookImages = useMemo(() => {
    if (!post?.facebook_medias || post.facebook_medias.length == 0) {
      return <></>;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {post.facebook_medias.map((media, index) =>
          index == 0 ? (
            <></>
          ) : (
            <img style={{ marginBottom: "2.5rem" }} src={media.url} />
          )
        )}
      </div>
    );
  }, [post]);
  const datetime = useMemo(() => {
    return post?.updated_at;
  }, [post?.updated_at]);

  const socialDescription = useMemo(() => {
    if (!post?.body){
      return "";
    }
    return post.body.substr(0, Math.min(post.body.length, 50))
  })

  const dateStr = useMemo(() => {
    if(!post?.updated_at){
      return "";
    }
    return new Date(post.updated_at).toLocaleDateString();
  })
  // fetch post or use cache
  useEffect(() => {
    // cache дотор байгаа бол шууд харуулах
    let cache;
    if (posts) {
      cache = posts.filter((p) => p.id == id)[0];
    }
    if (cache) {
      // cache -ээс олдсон
      setPost(cache);
    } else {
      // cache дотор байхгүй
      // server -с авах
      fetchPost();
    }
  }, [id]);
  if (loading || !post) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Header/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Helmet>
        {/* social share title, images*/}

        {/* Facebook share */}
        <meta property="og:title" content={post.title} />
        <meta
          property="og:description"
          content={socialDescription}
        />
        <meta property="og:url" content={fullUrl} />
        <meta property="og:site_name" content={"mfinance.mn"} />
        <meta property="og:updated_time" content={post.updated_at} />
        <meta property="og:published_time" content={post.published_at} />
        <meta property="og:image" content={imageUrl} />
        {/* Facebook share end */}

        {/* Twitter share */}
        <meta property="twitter:title" content={post.title} />
        <meta
          property="twitter:description"
          content={socialDescription}
        />
        <meta property="twitter:url" content={fullUrl} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content={"mfinance.mn"} />
        <meta property="twitter:image" content={imageUrl} />
        {/* Twitter share end */}
      </Helmet>
      <div className="container">
        <div className="page-header bg gradient-nt-01 bg-news">
          <div className="back-home pull-left">
            <a href="/#info">
              <button type="submit" className="btn btn-success">Буцах</button>
            </a>
          </div>
          <br/>
        </div>
        <div className="item">
          <h3 className="text-center">{post.title}</h3>
          <div className="blog-meta">
            <div className="row">
              <div style={{ display: "flex" }}>
                <div className="col-6">
                  <p>
                    <span className="ti-folder" />{" "}
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      Мэдээ
                    </a>
                  </p>
                </div>
                <div className="col-6">
                  <p className="float-end">
                    <span className="ti-calendar" />{" "}
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {dateStr}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ display: "flex", justifyContent: "center" }}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  className="img-responsive"
                  alt="News Image"
                  width="75%"
                />
              ) : (
                <></>
              )}
            </div>
          </div><hr/>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <div
                className="content news-single"
                dangerouslySetInnerHTML={{ __html: newsBodyHtml }}
                style={{
                  width: '100%',
                }}
              ></div>
              <div
                className="embed-responsive embed-responsive-16by9 text-center"
                dangerouslySetInnerHTML={{ __html: embedCodeHtml }}
                style={{
                  width: '100%',
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            {facebookImages}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h4 className="news-single-date">
                {dateStr}
              </h4>
            </div>
          </div>
          <div className="col-1">
            <div className="float-end">
              <FacebookShareButton url={fullUrl}>
                <a href="#" onClick={() => false}>
                  <i className="bi bi-facebook" style={{ fontSize: '1.5em' }}/>
                </a>
              </FacebookShareButton>
            </div>
          </div>
          <div className="col-1">
            <TwitterShareButton url={fullUrl}>
              <a href="#" onClick={() => false}>
                <i className="bi bi-twitter" style={{ fontSize: '1.5em' }}/>
              </a>
            </TwitterShareButton>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
