import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import Post from "../components/post";

const calcPageCount = (postCount) => {
  return Math.ceil(postCount / process.env.REACT_APP_PAGINATION) || 1;
};

export default function Info(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/posts?_limit=4&_sort=id:DESC`
    );
    setPosts(await response.json());
  };
  useEffect(() => {
   // fetchPosts();
  }, []);

  const [pageCount, setPageCount] = useState(0);

  const fetchPage = async (page) => {
    const url = `${process.env.REACT_APP_API_URL}/posts?_sort=id:DESC&_start=${
      process.env.REACT_APP_PAGINATION * (page - 1)
    }&_limit=${process.env.REACT_APP_PAGINATION}`;
      const response = await fetch(url);
      
      setPosts(await response.json());
      if (!pageCount) {
        const postCountResponse = await fetch(`${process.env.REACT_APP_API_URL}/posts/count`);
        const pageCount = calcPageCount(await postCountResponse.json());
        setPageCount(pageCount);
      }
  };

  // dynamic pagination
  const handlePagination = async (page) => {
    fetchPage(page);
  };

  return (
    <section id="info" className="portfolio">
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="container">
        <h2>Мэдээ мэдээлэл</h2>
        <br/>
        <div className="row" style={{ textAlign: "center" }}>
          <ReactPaginate
            initialPage={0}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            onPageChange={({ selected }) => {
              handlePagination(selected + 1);
            }}
            containerClassName={"pagination"}
            activeClassName={"page-item active"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link text-dark"}
            activeLinkClassName={"page-link bg-success text-white"}
            pageLinkClassName={"page-link text-dark"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link text-dark"}
          />
        </div>
        <div className="row">
          {posts.map((p) => (
            <div key={p.id} className="col-lg-3 col-md-6 col-sm-12">
              <Post {...p} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
