import React, { Component } from 'react';
 
import Header from '../components/header';
import Footer from '../components/footer';
import Services from '../components/services';

class service1 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Дугаар барьцаалсан зээл:</h1>
                    <br></br>
                    <br></br>
                    <div className="page-padding post-99 page type-page status-publish hentry">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Зээлийн нөхцөл</strong></td>
                                            <td>9911, 8811, 9909</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хэмжээ</strong></td>
                                            <td>9911 – 20 сая , 8811 – 5 сая , 9909 – 3 сая</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>12 сар хүртэл</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү</strong></td>
                                            <td>3,5%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>1%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <h3>Тавигдах шаардлага:</h3>
                                        <ul>
                                            <li>Монгол улсын иргэн</li>
                                            <li>Өөрийн эзэмшлийн дугаар байх</li>
                                        </ul>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <h3>Иргэний хувьд:</h3>
                                        <ul>
                                            <li>Зээлийн өргөдөл, зураг /ББСБ-ын маягтийн дагуу/</li>
                                            <li>Иргэний үнэмлэх /Хуулбар/</li>
                                            <li>Худалдан авах хөрөнгийн төлбөрийн нэхэмжлэх, барааны тодорхойлолт, харангийн үзүүлэлт, гаалийн мэдүүлэгийн хуулбар</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <Services/>
                <Footer/>
            </div>
        )
    }
}
export default service1;