import React, { Component } from 'react';
 
import Header from '../components/header';
import Footer from '../components/footer';
import Services from '../components/services';

class service1 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Үл хөдлөх барьцаалсан зээл:</h1>
                    <br></br>
                    <br></br>
                    <div className="page-padding post-99 page type-page status-publish hentry">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <h2>Сургалтын зээл:</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Зээлийн хэмжээ</strong></td>
                                            <td>30%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>30 сар</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү</strong></td>
                                            <td>2,8-3,5%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>1%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <h3>Тавигдах шаардлага:</h3>
                                    <ul>
                                        <li>Улаанбаатар хотод үйл ажиллагаа явуулж байгаа Их, Дээд сургууль</li>
                                        <li>Тус сургуульдаа 2-р курсид дэвшин суралцах оюутан</li>
                                        <li>Тухайн оюутаны голч нь Хувийн сургууль /3,2/ Улсын сургууль /2,6/</li>
                                        <li>Зээл төлөх орлогын эх үүсвэртэй байх</li>
                                        <li>Бусдад хугацаа хэтэрсэн өр төлбөргүй, түүхгүй байх</li>
                                        <li>Зээл хамтран хариуцагч нь гэр бүлийн гишүүн /нөхөр, эхнэр, аав, ээж/ байна.</li>
                                    </ul>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <h3>Бүрдүүлэх бичиг баримт:</h3>
                                    <ul>
                                        <li>Сургуулийн тодорхойлолт</li>
                                        <li>Сургалтын төлбөрийн нэхэмжлэх</li>
                                        <li>Зээл хариуцагчийн анкет</li>
                                        <li>Зээлийн өргөдөл</li>
                                        <li>Иргэний үнэмлэх</li>
                                        <li>Оршин суугаа хаягийн лавлагаа /ТҮЦ машин/</li>
                                        <li>Гэрлэлтийн лавлагаа /ТҮЦ машин/</li>
                                        <li>Нийгмийн даатгалийн лавлагаа /ТҮЦ машин/</li>
                                        <li>Байгууллагын тодорхойлолт Дансны хуулга /Сүүлийн 7 сар/</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <h2>Аялалын зээл</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong>Зээлийн хэмжээ</strong></td>
                                                <td>Тухайн аялалд хамрагдах төлбөр</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Зээлийн хугацаа</strong></td>
                                                <td>6 сар</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Зээлийн хүү</strong></td>
                                                <td>5%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Зээлийн шимтгэл</strong></td>
                                                <td>1%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <h3>Тавигдах шаардлага:</h3>
                                    <ul>
                                        <li>Улаанбаатар хотод оршин суудаг байх</li>
                                        <li>Бусдад хугацаа хэтэрсэн өр төлбөргүй байх</li>
                                        <li>Зээл төлөх орлогын эх үүсвэртэй байх</li>
                                    </ul>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <h3>Бүрдүүлэх бичиг баримт:</h3>
                                    <ul>
                                        <li>Харилцагчийн анкет</li>
                                        <li>Зээлийн өргөдөл</li>
                                        <li>Иргэний үнэмлэх</li>
                                        <li>Оршин суугаа хаягийн лавлагаа /ТҮЦ машин/</li>
                                        <li>Гэрлэлтийн лавлагаа /ТҮЦ машин/</li>
                                        <li>Нийгмийн даатгалийн лавлагаа /ТҮЦ машин/</li>
                                        <li>Байгууллагын тодорхойлолт</li>
                                        <li>Дансны хуулга /Сүүлийн 7 сар/</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Services/>
                <Footer/>
            </div>
        )
    }
}
export default service1;