import React, { Component } from 'react';
 
import Header from '../components/header';
import Footer from '../components/footer';
import Services from '../components/services';

class service1 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Үл хөдлөх барьцаалсан зээл:</h1>
                    <br></br>
                    <br></br>
                    <div className="page-padding post-99 page type-page status-publish hentry">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2>Хашаа байшин барьцаалсан зээл</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Зээлийн хэмжээ</strong></td>
                                            <td>Барьцаа хөрөнгийн 30-40% хүртэл</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн дээд хэмжээ</strong></td>
                                            <td>20 сая төгрөг хүртэл</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>12 сар хүртэл</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү</strong></td>
                                            <td>3,5-4%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>1%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h2>Орон сууц барьцаалсан зээл</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Зээлийн хэмжээ</strong></td>
                                        <td>Барьцаа хөрөнгийн 30-40% хүртэл</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн дээд хэмжээ</strong></td>
                                        <td>100 сая төгрөг хүртэл</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн хугацаа</strong></td>
                                        <td>18 сар хүртэл</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн хүү</strong></td>
                                        <td>3,5%</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн шимтгэл</strong></td>
                                        <td>1%</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div className="col-12">
                                <h1>Бүрдүүлэх бичиг баримт:</h1>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h3>Тавигдах шаардлага:</h3>
                                <ul>
                                    <li>Барьцаалж буй хөрөнгө нь зээлийн хүсэгчийн өөрийн эзэмшлийнх байх</li>
                                    <li>Улаанбаатар хотод оршин суудаг байх</li>
                                    <li>Бусдад хугацаа хэтэрсэн өр төлбөргүй байх</li>
                                    <li>Зээл төлөх орлогын эх үүсвэртэй байх</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h3>Бүрдүүлэх бичиг баримт:</h3>
                                <ul>
                                    <li>Харилцагчийн анкет, Цээж зураг /ББСБ-ын маягтын дагуу/</li>
                                    <li>Зээлийн өргөдөл /А4 цаас дээр/</li>
                                    <li>Оршин суугаа хаягийн лавлагаа /ТҮЦ машин/</li>
                                    <li>Барьцаанд тавигдах хөрөнгийн бичиг баримт, лавлагаа /ТҮЦ машин/</li>
                                    <li>Зээл төлөх орлогыг баталгаажуулах баримтууд</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <Services/>
                <Footer/>
            </div>
        )
    }
}
export default service1;