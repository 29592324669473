import React, { Component } from 'react';
 
import Header from '../components/header';
import Footer from '../components/footer';
import Services from '../components/services';

class service1 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Тээврийн хэрэгсэл барьцаалсан зээл:</h1>
                    <br></br>
                    <br></br>
                    <div className="page-padding post-99 page type-page status-publish hentry">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2>Унаж явхаар зээлийн нөхцөл</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Зээлийн хэмжээ</strong></td>
                                        <td>Зах зээлийн үнийн дүнгийн 30% хүртэл</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Сарын хүү</strong></td>
                                        <td>3,5-4%</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн хугацаа</strong></td>
                                        <td>1-6 сар хүртэлх</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн шимтгэл</strong></td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Даатгал</strong></td>
                                        <td>Зээлийн хугацаанд даатгал хийх</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Барьцаа барьцаалж болно</strong></td>
                                        <td>Урьдчилгаа 0-30%-д үл хөдлөх</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h2>Тавихаар зээлийн нөхцөл</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Зээлийн хэмжээ</strong></td>
                                        <td>Зах зээлийн үнийн дүнгийн 50% хүртэл</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Сарын хүү</strong></td>
                                        <td>3-3.5%</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн хугацаа</strong></td>
                                        <td>1-12 сар хүртэлх</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Зээлийн шимтгэл</strong></td>
                                        <td>0.5%</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Даатгал</strong></td>
                                        <td>Шаардлагагүй</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <h3>Бүрдүүлэх бичиг баримт:</h3>
                                <ul>
                                    <li>Харилцагчийн анкет /ББСБ-ын маягтын дагуу/</li>
                                    <li>Зээлийн өргөдөл</li>
                                    <li>Иргэний үнэмлэх хуулбар</li>
                                    <li>Тээврийн хэрэгсэлийн гэрчилгээ</li>
                                    <li>Оршин суугаа хаягийн тодорхойлолт</li>
                                    <li>Орлого баталгаажуулах баримтууд</li>
                                </ul>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h3>Барьцаа хөрөнгөд тавигдах шаардлага:</h3>
                                <ul>
                                    <li>2006 оноос хойш үйлдвэрлэгдсэн</li>
                                    <li>3 жилээс доош хугацаанд МУ-д явсан</li>
                                    <li>Зээл хүсэгчийн өөрийн эзэмшлийнх байх</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h3>Зээлдэгчдэд тавигдах шаардлага:</h3>
                                <ul>
                                    <li>Улаанбаатар хотод тогтвортой оршин суугч</li>
                                    <li>Бусдад хугацаа хэтэрсэн өр төлбөргүй байх</li>
                                    <li>Зээл төлөх орлогын эх үүсвэртэй байх</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <Services/>
                <Footer/>
            </div>
        )
    }
}
export default service1;