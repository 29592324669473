import React, { Component } from 'react';
 
import Header from '../components/header';
import Footer from '../components/footer';
import Services from '../components/services';

class service1 extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Бизнесийн зээл:</h1>
                    <br></br>
                    <br></br>
                    <div className="page-padding post-99 page type-page status-publish hentry">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h2>Бичил бизнесийн зээл</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Зээлийн хэмжээ</strong></td>
                                            <td>5 сая төгрөг</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>12 сар</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү</strong></td>
                                            <td>2,5-3,5%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>1%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h2>Жижиг дунд бизнесийн зээл</h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>Зээлийн хэмжээ</strong></td>
                                            <td>150 сая төгрөг хүртэл</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хугацаа</strong></td>
                                            <td>24 сар</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн хүү</strong></td>
                                            <td>3-4%</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Зээлийн шимтгэл</strong></td>
                                            <td>0.5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h3>Тавигдах шаардлага:</h3>
                                <ul>
                                    <li>Бизнесийн үйл ажиллагаа 6 сар явуулсан, тухайн бизнес нь тогтворжсон байх</li>
                                    <li>Улаанбаатар хотод үйл ажиллагаа явуулдаг байх</li>
                                    <li>Бизнесийн зөвшөөрлийн бичиг баримттай байх</li>
                                    <li>Бусдад хугацаа хэтэрсэн өр төлбөргүй байх</li>
                                    <li>Хангалттай хэмжээний барьцаалах хөрөнгөтэй байх</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <h3>Иргэний хувьд:</h3>
                                <ul>
                                    <li>Харилцагчийн анкет /ББСБ-ын маягтын дагуу/</li>
                                    <li>Зээлийн өргөдөл /А4 цаасан дээр/</li>
                                    <li>Иргэний үнэмлэх</li>
                                    <li>Барьцаанд тавигдах хөрөнгий бичиг баримтууд, лавлагаа /ТҮЦ/</li>
                                    <li>Бизнес эрхлэх зөвшөөрлийн бичиг баримтууд</li>
                                    <li>Орлого баталгаажуулах баримтууд, дансны хуулга</li>
                                    <li>Бусад</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <h2>Байгууллагын хувьд:</h2>
                                <ul>
                                    <li>Зээл хариуцагчийн анкет /ББСБ-ын маягтын дагуу/</li>
                                    <li>Зээлийн өргөдөл</li>
                                    <li>ААН- гэрчилгээ, дүрэм</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <Services/>
                <Footer/>
            </div>
        )
    }
}
export default service1;