import React, { Component } from "react";

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    if (this.props.message == null) {
      var propMessage = null;
    } else {
      var propMessage = (
        <div className="carousel-content animate__animated animate__fadeInUp">
          <h2 style={{ textAlign: "center", maxWidth: "100%", height: "auto" }}>
            {this.props.message}
          </h2>
        </div>
      );
    }
    return (
      <>
        <section id="hero">
          {/* <img
            className="hero-bg-image"
            srcSet="assets/images/Coverg-sm.png 320w, assets/images/Coverg.png 800w"
          /> */}
          <img className="hero-bg-image" src="/assets/images/Cover.png" />
          <div>{this.props.children}</div>
        </section>
      </>
    );
  }
}

export default Hero;
