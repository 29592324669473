import React, { Component } from "react";
import $ from "jquery";

class Contact extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit(e) {
    e.preventDefault();

    var form = $("#formSubmit");

    var unindexed_array = form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
      indexed_array[n["name"]] = n["value"];
    });

    var data = indexed_array;

    function jsonEscapeUTF(s) {
      return s.replace(
        /[^\x20-\x7F]/g,
        (x) => "\\u" + ("000" + x.codePointAt(0).toString(16)).slice(-4)
      );
    }

    var bodyLocal = {
      jsonrpc: "2.0",
      params: {
        last_name: data["last_name"],
        sur_name: data["sur_name"],
        com_name: data["com_name"],
        register: data["register"],
        phone: data["phone"],
        email: data["email"],
        company: data["company"],
        year: data["year"],
        job: data["job"],
        certificate_number: data["certificate_number"],
        monthly_income: data["monthly_income"],
        origin_income: data["origin_income"],
        type_id: data["type_id"],
        loan_amount: data["loan_amount"],
        collateral: data["collateral"],
        loan_period: data["loan_period"],
        employment_status: data["employment_status"],
        purpose_id: data["purpose_id"],
        operation_type: data["operation_type"],
        operation_year: data["operation_year"],
        emp_name: data["emp_name"],
        website: data["website"],
        address: data["address"],
        duration_of_employment: data["duration_of_employment"],
        manager_name: data["manager_name"],
        manager_phone: data["manager_phone"],
        is_paid_animal_tax: data["is_paid_animal_tax"],
        duration_of_farmer: data["duration_of_farmer"],
        is_registered_animal: data["is_registered_animal"],
        is_insurance_animal: data["is_insurance_animal"],
        is_insurance: data["is_insurance"],
        is_reported_tax: data["is_reported_tax"],
        profession: data["profession"],
        is_than_score: data["is_than_score"],
        school: data["school"],
        course: data["course"],
        pension_book_number: data["pension_book_number"],
        pension_date: data["pension_date"],
        partner_id: localStorage.getItem("partner_id")
      },
    };

    var raw = JSON.stringify(bodyLocal).replace(
      /[^\x20-\x7F]/g,
      (x) => "\\u" + ("000" + x.codePointAt(0).toString(16)).slice(-4)
    )
    
    $.ajax({
      url: "https://erp.mfinance.mn/website/request",
      method: "POST",
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      },
      headers: {
        "Content-Type": "application/json"
      },
      data: raw,
      success: function(response) {
        try{
          alert(response.result);
          window.location= "/";
        }catch{
          alert(response.error);
        }
      }
    }); 
  }

  componentDidMount() {
    $(function () {
      $("#emp_stat").change(function () {
        $(".emp").hide();
        $("#" + $(this).val()).show();
        var val = $(this).val();
        if (val != "company") {
          $("#js_loan_request_company").hide();
          $("#js_loan_request_company1").hide();
          $("#js_loan_request_company2").hide();
          $("#js_loan_request_company3").hide();
          $("#js_loan_request_person").show();
          $("#js_loan_request_person1").show();
          $("#js_loan_request_person2").show();
          $("#js_loan_request_person3").show();
          $("div.person").show();
          $("div.company").hide();
        } else {
          $("#js_loan_request_company").show();
          $("#js_loan_request_company1").show();
          $("#js_loan_request_company2").show();
          $("#js_loan_request_company3").show();
          $("#js_loan_request_person").hide();
          $("#js_loan_request_person1").hide();
          $("#js_loan_request_person2").hide();
          $("#js_loan_request_person3").hide();
          $("div.person").hide();
          $("div.company").show();
        }
      });
    });
  }

  render() {
    return (
      <section id="contact">
        <br />
        <br />
        <br />
        <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div
                className="mapouter"
                style={{
                  position: "relative",
                  textAlign: "right",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  className="gmap_canvas"
                  style={{
                    overflow: "hidden",
                    background: "none !important",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <iframe
                    width="100%"
                    height="600"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=gerege%20tower&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div id="btnTop" className="fixed-action-btn smooth-scroll">
              {localStorage.getItem("name") ? (
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ position: "fixed", bottom: "0px", right: "0px" }}
                >
                  Зээлийн хүсэлт
                </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ position: "fixed", bottom: "0px", right: "0px" }}
                    disabled
                  >
                    Зээлийн хүсэлт (Та нэвтрэх шаардлагатай)
                  </button>
                )}
              
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Зээлийн хүсэлт илгээх
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body" style={{ overflowY: "auto" }}>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <div className="row">
                            <div className="col-lg-12 col-sm-12">
                              <form
                                onSubmit={this.handleSubmit}
                                id="formSubmit"
                              >
                                Ажил Эрхлэлтийн байдал:
                                <select
                                  className="form-control o_website_form_input emp_stat"
                                  name="employment_status"
                                  id="emp_stat"
                                  required="required"
                                >
                                  <option value="defa">...</option>
                                  <option value="company">Компани</option>
                                  <option value="corp_emp">
                                    Аж ахуй нэгжийн ажилтан
                                  </option>
                                  <option value="farmer">Малчин</option>
                                  <option value="student">Оюутан</option>
                                  <option value="government_emp">
                                    Төрийн албан хаагч
                                  </option>
                                  <option value="retired">
                                    Тэтгэвэрт гарсан
                                  </option>
                                  <option value="self_emp">
                                    Хувиараа хөдөлмөр эрхлэгч
                                  </option>
                                </select>
                                <hr />
                                <div
                                  className="col-sm-12"
                                  style={{ height: "70vh" }}
                                >
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div
                                          className="col-sm-4 person"
                                          style={{ display: "none" }}
                                        >
                                          <label
                                            className="control-label js_loan_request_person"
                                            id="js_loan_request_person"
                                            htmlFor="last_name"
                                          >
                                            Овог
                                          </label>
                                          <input
                                            type="text"
                                            name="last_name"
                                            className="form-control js_loan_request_person1"
                                            id="js_loan_request_person1"
                                          />
                                        </div>
                                        <div
                                          className="col-sm-4 person"
                                          style={{ display: "none" }}
                                        >
                                          <label
                                            className="control-label js_loan_request_person2"
                                            id="js_loan_request_person2"
                                            htmlFor="sur_name"
                                          >
                                            Нэр
                                          </label>
                                          <input
                                            type="text"
                                            name="sur_name"
                                            className="form-control js_loan_request_person3"
                                            id="js_loan_request_person3"
                                          />
                                        </div>
                                        <div className="col-sm-4 company">
                                          <label
                                            className="control-label js_loan_request_company"
                                            id="js_loan_request_company"
                                            htmlFor="com_name"
                                          >
                                            Компаний нэр
                                          </label>
                                          <input
                                            type="text"
                                            name="com_name"
                                            className="form-control js_loan_request_company1"
                                            id="js_loan_request_company1"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-4 company">
                                          <label
                                            className="control-label js_loan_request_company2"
                                            id="js_loan_request_company2"
                                            htmlFor="certificate_number"
                                          >
                                            Гэрчилгээний дугаар
                                          </label>
                                          <input
                                            type="text"
                                            name="certificate_number"
                                            className="form-control js_loan_request_company3"
                                            id="js_loan_request_company3"
                                          />
                                        </div>
                                        <div className="col-sm-4">
                                          <label
                                            className="control-label"
                                            htmlFor="register"
                                          >
                                            Регистр
                                          </label>
                                          <input
                                            type="text"
                                            name="register"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-8">
                                      <div className="row">
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label"
                                            htmlFor="phone"
                                          >
                                            Утас
                                          </label>
                                          <input
                                            type="number"
                                            minLength="8"
                                            maxLength="8"
                                            name="phone"
                                            className="form-control"
                                            required="required"
                                          />
                                        </div>
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label"
                                            htmlFor="email"
                                          >
                                            Имэйл хаяг
                                          </label>
                                          <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            required="required"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row emp" id="defa" />
                                  <div
                                    className="row emp"
                                    id="government_emp"
                                    style={{ display: "none" }}
                                  >
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-4 person">
                                          <label
                                            className="control-label per3"
                                            htmlFor="year"
                                            id="per3"
                                          >
                                            Жил
                                          </label>
                                          <input
                                            type="float"
                                            name="year"
                                            className="form-control per4"
                                            id="per4"
                                          />
                                        </div>
                                        <div className="col-sm-4 person">
                                          <label
                                            className="control-label per5"
                                            htmlFor="job"
                                            id="per5"
                                          >
                                            Ажил
                                          </label>
                                          <input
                                            type="text"
                                            name="job"
                                            className="form-control per6"
                                            id="per6"
                                          />
                                        </div>
                                        <div className="col-sm-4 person">
                                          <label
                                            className="control-label per7"
                                            htmlFor="monthly_income"
                                            id="per7"
                                          >
                                            Сарын орлого
                                          </label>
                                          <input
                                            type="float"
                                            name="monthly_income"
                                            className="form-control per8"
                                            id="per8"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-6 person">
                                          <label
                                            className="control-label manager_name"
                                            htmlFor="manager_name"
                                            id="manager_name"
                                          >
                                            Менежерийн нэр
                                          </label>
                                          <input
                                            type="text"
                                            name="manager_name"
                                            className="form-control manager_name"
                                            id="manager_name"
                                          />
                                        </div>
                                        <div className="col-sm-6 person">
                                          <label
                                            className="control-label manager_phone"
                                            htmlFor="manager_phone"
                                            id="manager_phone"
                                          >
                                            Менежерийн утас
                                          </label>
                                          <input
                                            type="text"
                                            name="manager_phone"
                                            className="form-control manager_phone"
                                            id="manager_phone"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row emp"
                                    id="farmer"
                                    style={{ display: "none" }}
                                  >
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <label
                                          className="control-label"
                                          htmlFor="duration_of_farmer"
                                        >
                                          Мал малласан жил
                                        </label>
                                        <input
                                          type="text"
                                          name="duration_of_farmer"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <label
                                          className="control-label"
                                          htmlFor="is_paid_animal_tax"
                                        >
                                          Малын хөлийн татвар төлсөн эсэх
                                        </label>
                                        <input
                                          type="checkbox"
                                          value="true"
                                          t-att-checked="checked"
                                          name="is_paid_animal_tax"
                                          className="form-check-input"
                                        />
                                      </div>
                                      <div className="col-sm-4">
                                        <label
                                          className="control-label"
                                          htmlFor="is_registered_animal"
                                        >
                                          Мал амьтад бүртгэлтэй эсэх
                                        </label>
                                        <input
                                          type="checkbox"
                                          value="true"
                                          t-att-checked="checked"
                                          name="is_registered_animal"
                                          className="form-check-input"
                                        />
                                      </div>
                                      <div className="col-sm-4">
                                        <label
                                          className="control-label"
                                          htmlFor="is_insurance_animal"
                                        >
                                          Малын даатгалтай эсэх
                                        </label>
                                        <input
                                          type="checkbox"
                                          value="true"
                                          t-att-checked="checked"
                                          name="is_insurance_animal"
                                          className="form-check-input"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row emp"
                                    id="self_emp"
                                    style={{ display: "none" }}
                                  >
                                    <div className="col-sm-6">
                                      <label
                                        className="control-label"
                                        htmlFor="is_insurance"
                                      >
                                        Даатгалтай эсэх
                                      </label>
                                      <input
                                        type="checkbox"
                                        value="true"
                                        t-att-checked="checked"
                                        name="is_insurance"
                                        className="form-check-input"
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <label
                                        className="control-label"
                                        htmlFor="is_reported_tax"
                                      >
                                        Татвар тайлагнадаг эсэх
                                      </label>
                                      <input
                                        type="checkbox"
                                        value="true"
                                        t-att-checked="checked"
                                        name="is_reported_tax"
                                        className="form-check-input"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="row emp"
                                    id="retired"
                                    style={{ display: "none" }}
                                  >
                                    <div className="col-sm-6">
                                      <label
                                        className="control-label"
                                        htmlFor="pension_book_number"
                                      >
                                        Тэтгэврийн дэвтрийн дугаар
                                      </label>
                                      <input
                                        type="text"
                                        name="pension_book_number"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <label
                                        className="control-label"
                                        htmlFor="pension_date"
                                      >
                                        Тэтгэвэрт гарсан огноо
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control o_website_form_date"
                                        name="pension_date"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="row emp"
                                    id="student"
                                    style={{ display: "none" }}
                                  >
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <label
                                          className="control-label"
                                          htmlFor="profession"
                                        >
                                          Мэргэжил
                                        </label>
                                        <input
                                          type="text"
                                          name="profession"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-sm-6">
                                        <label
                                          className="control-label"
                                          htmlFor="school"
                                        >
                                          Сургуулийн нэр
                                        </label>
                                        <input
                                          type="text"
                                          name="school"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <label
                                          className="control-label"
                                          htmlFor="course"
                                        >
                                          Курс
                                        </label>
                                        <select
                                          className="form-control o_website_form_input emp_stat"
                                          name="course"
                                          id="course"
                                        >
                                          <option value="course1">1</option>
                                          <option value="course2">2</option>
                                          <option value="course3">3</option>
                                          <option value="course4">4, 4+</option>
                                        </select>
                                      </div>
                                      <div className="col-sm-6">
                                        <label
                                          className="control-label"
                                          htmlFor="is_than_score"
                                        >
                                          Голч оноо 2.8 аас их эсэх
                                        </label>
                                        <br />
                                        <input
                                          type="checkbox"
                                          value="true"
                                          t-att-checked="checked"
                                          name="is_than_score"
                                          className="form-check-input"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row emp"
                                    id="company"
                                    style={{ display: "none" }}
                                  >
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label com1"
                                            htmlFor="operation_type"
                                            id="com1"
                                          >
                                            Үйл ажиллагааний чиглэл
                                          </label>
                                          <select
                                            id="operation_type"
                                            name="operation_type"
                                            className="form-control"
                                          >
                                            <option value="">Үйл ажиллагааний чиглэл</option>
                                            <option value="a">
                                              ХАА, АН АГНУУР, ОЙН АА, ЗАГАС
                                              БАРИЛТ
                                            </option>
                                            <option value="b">
                                              Газар тариалан, МААН үйлдвэрлэл,
                                              Ан агнуур болон түүнд туслах ҮА
                                            </option>
                                            <option value="c">
                                              Ойн АА, Мод бэлтгэл
                                            </option>
                                            <option value="d">
                                              Загас агнуур, үржүүлэг
                                            </option>
                                            <option value="e">
                                              Цахилгаан хий, агааржуулалтын
                                              хангамж
                                            </option>
                                            <option value="f">
                                              Цахилгаан хий, агааржуулалтын
                                              хангамж
                                            </option>
                                            <option value="g">БАРИЛГА</option>
                                            <option value="h">
                                              Барилга угсралт
                                            </option>
                                            <option value="i">
                                              Инженерийн байгууламжын угсралтын
                                              ҮА
                                            </option>
                                            <option value="j">
                                              Барилгын тусгай үйл ажиллагаа
                                            </option>
                                            <option value="k">
                                              УУЛ УУРХАЙ, ОЛБОРЛОХ ҮЙЛДВЭР
                                            </option>
                                            <option value="l">
                                              Чулуун нүүрс, хүрэн нүүрс
                                              олборлолт
                                            </option>
                                            <option value="m">
                                              Түүхий газрын тос, байгалийн хий
                                              олборлолт
                                            </option>
                                            <option value="n">
                                              Уул уурхайн олборлолт
                                            </option>
                                            <option value="o">
                                              Төмрийн хүдэр олборлолт
                                            </option>
                                            <option value="p">
                                              Бусад ашигт малтмал олборлолт
                                            </option>
                                            <option value="q">
                                              БОЛОВСРУУЛАХ ҮЙЛДВЭР
                                            </option>
                                            <option value="r">
                                              Хүнсний бүтээгдэхүүн үйлдвэрлэл
                                            </option>
                                            <option value="s">
                                              Эм, ургамлын болон химийн гаралтай
                                              эмийн бүтээгдэхүүн үйлдвэрлэл
                                            </option>
                                            <option value="t">
                                              Нэхмэлийн үйлдвэрлэл
                                            </option>
                                            <option value="u">
                                              Хувцас үйлдвэрлэл
                                            </option>
                                            <option value="24">
                                              Бусад
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label com3"
                                            htmlFor="operation_year"
                                            id="com3"
                                          >
                                            Үйл ажиллагаа явуулсан хугацаа
                                          </label>
                                          <input
                                            type="float"
                                            name="operation_year"
                                            className="form-control com4"
                                            id="com4"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label com5"
                                            htmlFor="emp_name"
                                            id="com5"
                                          >
                                            Холбоо барих ажилтны нэр
                                          </label>
                                          <input
                                            type="text"
                                            name="emp_name"
                                            className="form-control com6"
                                            id="com6"
                                          />
                                        </div>
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label com7"
                                            htmlFor="website"
                                            id="com7"
                                          >
                                            Вэбсайт
                                          </label>
                                          <input
                                            type="text"
                                            name="website"
                                            className="form-control com8"
                                            id="com8"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <label
                                            className="control-label com9"
                                            htmlFor="address"
                                            id="com9"
                                          >
                                            Хаяг
                                          </label>
                                          <input
                                            type="text"
                                            name="address"
                                            className="form-control com10"
                                            id="com10"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row emp"
                                    id="corp_emp"
                                    style={{ display: "none" }}
                                  >
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-6 person">
                                          <label
                                            className="control-label per1"
                                            htmlFor="company"
                                            id="per1"
                                          >
                                            Ажилладаг компани
                                          </label>
                                          <input
                                            type="text"
                                            name="company"
                                            className="form-control per2"
                                            id="per2"
                                          />
                                        </div>
                                        <div className="col-sm-3 person">
                                          <label
                                            className="control-label per3"
                                            htmlFor="year"
                                            id="per3"
                                          >
                                            Жил
                                          </label>
                                          <input
                                            type="float"
                                            name="year"
                                            className="form-control per4"
                                            id="per4"
                                          />
                                        </div>
                                        <div className="col-sm-3 person">
                                          <label
                                            className="control-label per5"
                                            htmlFor="job"
                                            id="per5"
                                          >
                                            Ажил
                                          </label>
                                          <input
                                            type="text"
                                            name="job"
                                            className="form-control per6"
                                            id="per6"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-6 person">
                                          <label
                                            className="control-label per7"
                                            htmlFor="monthly_income"
                                            id="per7"
                                          >
                                            Сарын орлого
                                          </label>
                                          <input
                                            type="float"
                                            name="monthly_income"
                                            className="form-control per8"
                                            id="per8"
                                          />
                                        </div>
                                        <div className="col-sm-6 person">
                                          <label
                                            className="control-label per9"
                                            htmlFor="origin_income"
                                            id="per9"
                                          >
                                            Үндсэн орлого
                                          </label>
                                          <input
                                            type="text"
                                            name="origin_income"
                                            className="form-control per10"
                                            id="per10"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <label
                                            className="control-label"
                                            htmlFor="type_id"
                                          >
                                            Зээлийн төрөл
                                          </label>
                                          <select
                                            id="type_id"
                                            name="type_id"
                                            className="form-control"
                                            required="required"
                                          >
                                            <option value="">
                                              Зээлийн төрөл
                                            </option>
                                            <option value="8">
                                              Барьцаат зээл - Тээвэр - Унах
                                            </option>
                                            <option value="9">
                                              Барьцаат зээл - Тээвэр - Тавих
                                            </option>
                                            <option value="10">
                                              Барьцаат зээл - Дугаар - 9911
                                            </option>
                                            <option value="11">
                                              Барьцаат зээл - Дугаар - 8811
                                            </option>
                                            <option value="12">
                                              Барьцаат зээл - Үл хөдлөх - Орон
                                              сууц
                                            </option>
                                            <option value="13">
                                              Барьцаат зээл - Үл хөдлөх - Хашаа
                                              байшин
                                            </option>
                                            <option value="14">
                                              Барьцаагүй зээл - Хэрэглээ
                                            </option>
                                            <option value="15">
                                              Барьцаагүй зээл - Цалин
                                            </option>
                                            <option value="16">
                                              Барьцаагүй зээл - Төсөл
                                            </option>
                                            <option value="17">
                                              Барьцаагүй зээл - Аялал
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-sm-4">
                                          <label
                                            className="control-label"
                                            htmlFor="loan_amount"
                                          >
                                            Зээл хүсэж буй хэмжээ
                                          </label>
                                          <input
                                            type="integer"
                                            name="loan_amount"
                                            className="form-control"
                                            required="required"
                                          />
                                        </div>
                                        <div className="col-sm-4">
                                          <label
                                            className="control-label"
                                            htmlFor="collateral"
                                          >
                                            Барьцаа
                                          </label>
                                          <select
                                            id="collateral"
                                            name="collateral"
                                            className="form-control"
                                            required="required"
                                          >
                                            <option t-attf-value="'real_asset'">
                                              Үл хөдлөх хөрөнгө
                                            </option>
                                            <option t-attf-value="'asset'">
                                              Хөрөнгө
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label"
                                            htmlFor="phone"
                                          >
                                            Зээлийн хугацаа
                                          </label>
                                          <input
                                            type="integer"
                                            name="loan_period"
                                            className="form-control"
                                            required="required"
                                          />
                                        </div>
                                        <div className="col-sm-6">
                                          <label
                                            className="control-label"
                                            htmlFor="purpose_id"
                                          >
                                            Зорилго
                                          </label>
                                          <select
                                            id="purpose_id"
                                            name="purpose_id"
                                            className="form-control"
                                            required="required"
                                          >
                                            <option value="">Зориулалт</option>
                                            <option value="1">
                                              ХАА, АН АГНУУР, ОЙН АА, ЗАГАС
                                              БАРИЛТ
                                            </option>
                                            <option value="2">
                                              Газар тариалан, МААН үйлдвэрлэл,
                                              Ан агнуур болон түүнд туслах ҮА
                                            </option>
                                            <option value="3">
                                              Ойн АА, Мод бэлтгэл
                                            </option>
                                            <option value="4">
                                              Загас агнуур, үржүүлэг
                                            </option>
                                            <option value="5">
                                              Цахилгаан хий, агааржуулалтын
                                              хангамж
                                            </option>
                                            <option value="6">
                                              Цахилгаан хий, агааржуулалтын
                                              хангамж
                                            </option>
                                            <option value="7">БАРИЛГА</option>
                                            <option value="8">
                                              Барилга угсралт
                                            </option>
                                            <option value="9">
                                              Инженерийн байгууламжын угсралтын
                                              ҮА
                                            </option>
                                            <option value="10">
                                              Барилгын тусгай үйл ажиллагаа
                                            </option>
                                            <option value="11">
                                              УУЛ УУРХАЙ, ОЛБОРЛОХ ҮЙЛДВЭР
                                            </option>
                                            <option value="12">
                                              Чулуун нүүрс, хүрэн нүүрс
                                              олборлолт
                                            </option>
                                            <option value="13">
                                              Түүхий газрын тос, байгалийн хий
                                              олборлолт
                                            </option>
                                            <option value="14">
                                              Уул уурхайн олборлолт
                                            </option>
                                            <option value="15">
                                              Төмрийн хүдэр олборлолт
                                            </option>
                                            <option value="16">
                                              Бусад ашигт малтмал олборлолт
                                            </option>
                                            <option value="17">
                                              БОЛОВСРУУЛАХ ҮЙЛДВЭР
                                            </option>
                                            <option value="18">
                                              Хүнсний бүтээгдэхүүн үйлдвэрлэл
                                            </option>
                                            <option value="19">
                                              Эм, ургамлын болон химийн гаралтай
                                              эмийн бүтээгдэхүүн үйлдвэрлэл
                                            </option>
                                            <option value="20">
                                              Нэхмэлийн үйлдвэрлэл
                                            </option>
                                            <option value="21">
                                              Хувцас үйлдвэрлэл
                                            </option>
                                            <option value="22">
                                              Арьс, арьсан бүтээгдэхүүний
                                              үйлдвэрлэл
                                            </option>
                                            <option value="23">
                                              Модон бүтээгдэхүүний үйлдвэрлэл
                                            </option>
                                            <option value="24">
                                              Цаас, цаасан бүтээгдэхүүний
                                              үйлдвэрлэл
                                            </option>
                                            <option value="25">
                                              Хэвлэх, дуу бичих, дахин
                                              үйлдвэрлэх ҮА
                                            </option>
                                            <option value="26">
                                              Химийн төрлийн бүтээгдэхүүн
                                              үйлдвэрлэл
                                            </option>
                                            <option value="27">
                                              Хуванцар бүтээгдэхүүн үйлдвэрлэл
                                            </option>
                                            <option value="28">
                                              Төмрийн үйлдвэрлэл
                                            </option>
                                            <option value="29">
                                              Бусад боловсруулах үйлдвэрлэл
                                            </option>
                                            <option value="30">
                                              Цахилгаан тоног төхөөрөмжийн
                                              үйлдвэрлэл
                                            </option>
                                            <option value="31">
                                              Авто тээврийн хэрэгсэл үйлдвэрлэл
                                            </option>
                                            <option value="32">
                                              Ундааны үйлдвэрлэл
                                            </option>
                                            <option value="33">
                                              Тамхины үйлдвэрлэл
                                            </option>
                                            <option value="34">
                                              Тавилга үйлдвэрлэл
                                            </option>
                                            <option value="35">
                                              Компьютерийн төрлийн бүтээгдэхүүн
                                              үйлдвэрлэл
                                            </option>
                                            <option value="36">
                                              БӨӨНИЙ БОЛОН ЖИЖИГЛЭНГИЙН ХУДАЛДАА
                                            </option>
                                            <option value="37">
                                              Моторт тээврийн хэрэгсэл
                                            </option>
                                            <option value="38">
                                              Моторт тээврийн хэрэгсэлээс бусад
                                              жижиглэн худалдаа
                                            </option>
                                            <option value="39">
                                              Моторт тээврийн хэрэгсэлээс бусад
                                              бөөний худалдаа
                                            </option>
                                            <option value="40">
                                              ТЭЭВЭР АГУУЛАХЫН ҮЙЛ АЖИЛЛАГАА
                                            </option>
                                            <option value="41">
                                              Газрын болон хийн хоолойн тээвэр
                                            </option>
                                            <option value="42">
                                              Усан замын тээвэр
                                            </option>
                                            <option value="43">
                                              Агаарын тээвэр
                                            </option>
                                            <option value="44">
                                              Агуулахын тээврийн бусад үйл
                                              ажиллагаа
                                            </option>
                                            <option value="45">
                                              ҮЛ ХӨДЛӨХ ХӨРӨНГИЙН Ү.А
                                            </option>
                                            <option value="46">
                                              Үл хөдлөх хөрөнгийн үйл ажиллагаа
                                            </option>
                                            <option value="47">
                                              ТӨРИЙН УДИРДЛАГА, НИЙГМИЙН Ү.А
                                            </option>
                                            <option value="48">
                                              Төрийн удирдлага, батлан
                                              хамгаалахын журмын нийгмийн
                                              хамгаалал
                                            </option>
                                            <option value="49">
                                              БОЛОВСРОЛ
                                            </option>
                                            <option value="50">
                                              Боловсрол
                                            </option>
                                            <option value="51">
                                              ЭРҮҮЛ МЭНД НИЙГМИЙН Ү.А
                                            </option>
                                            <option value="52">
                                              Хүний эрүүл мэндийн Ү.А
                                            </option>
                                            <option value="53">
                                              Оршин суугчдад үзүүлэх халамжын
                                              Ү.А
                                            </option>
                                            <option value="54">
                                              Зориулалтын байрны тусламжын Ү.А
                                            </option>
                                            <option value="55">
                                              УРЛАГ ҮЗВЭР, ТОГЛООМ НААДАМ
                                            </option>
                                            <option value="56">
                                              Спорт зугаа цэнгэл, чөлөөт цагийн
                                              Ү.а
                                            </option>
                                            <option value="57">
                                              Мөрийтэй тоглоомын газрын Ү.а
                                            </option>
                                            <option value="58">
                                              Номын сан, архив музей болон бусад
                                              соёлын Ү.А
                                            </option>
                                            <option value="59">
                                              САНХҮҮГИЙН БОЛОН ДААТГАЛЫН Ү.А
                                            </option>
                                            <option value="60">
                                              Даатгал, тээврээс бусад санхүүгийн
                                              Ү.А
                                            </option>
                                            <option value="61">
                                              Санхүүгийн бусад Ү.А
                                            </option>
                                            <option value="62">
                                              ХҮН ХӨЛСЛӨН АЖИЛУУЛДАГ ӨРХИЙН Ү.А
                                            </option>
                                            <option value="63">
                                              Хүн хөлслөн ажилуулдаг өрхийн Ү.А
                                            </option>
                                            <option value="64">
                                              Хүн хөлслөн ажилуулдаг бусад
                                              өрхийн Ү.А
                                            </option>
                                            <option value="65">
                                              УСАН ХАНГАМЖ, БОХИР УС, ХОГ
                                              ХАЯГДЛЫН МЕНЕЖМЕНТ
                                            </option>
                                            <option value="66">
                                              Ус хуримтлуулах, ариутгах сан
                                              хангамжын Ү.А
                                            </option>
                                            <option value="67">
                                              Хог хаягдал цуглуулах,
                                              боловсруулах Ү.А
                                            </option>
                                            <option value="68">
                                              БАЙР СУУЦ БОЛОН ХҮНСЭЭР ҮЙЛЧЛЭХ
                                              Ү.А
                                            </option>
                                            <option value="69">
                                              Байр сууцаар хангах Ү.А
                                            </option>
                                            <option value="70">
                                              Хоол ундаар үйлчлэх Ү.А
                                            </option>
                                            <option value="71">
                                              МЭДЭЭЛЭЛ ХОЛБООНЫ Ү.А
                                            </option>
                                            <option value="72">
                                              Эх бэлтгэл, нийтлэлийн Ү.А
                                            </option>
                                            <option value="73">
                                              Кино, видео, телевизийн Ү.А
                                            </option>
                                            <option value="74">
                                              Цахилгаан холбоо
                                            </option>
                                            <option value="75">
                                              Компьютер програмчлал Ү.А
                                            </option>
                                            <option value="76">
                                              Мэдээллээр хангах Ү.А
                                            </option>
                                            <option value="77">
                                              ЗАХИРГААНЫ БОЛОН ДЭМЖЛЭГ ҮЗҮҮЛЭХ
                                              Ү.А
                                            </option>
                                            <option value="78">
                                              Түрээсийн Ү.А
                                            </option>
                                            <option value="79">
                                              Аялал жуулчлалын агентлаг, аялалын
                                              Ү.А
                                            </option>
                                            <option value="80">
                                              Аюулгүй хамгаалалын Ү.А
                                            </option>
                                            <option value="81">
                                              Байшин барилга, газрын талаархи
                                              Ү.А
                                            </option>
                                            <option value="82">
                                              Техникийн Ү.А
                                            </option>
                                            <option value="83">
                                              БИЗНЕСИЙН Ү.А
                                            </option>
                                            <option value="84">
                                              Бизнесийн үйл ажиллагаа эрхлэх
                                            </option>
                                            <option value="85">
                                              Бусад хувийн үйлчилгээ
                                            </option>
                                            <option value="86">
                                              Ажил эрхлэлтийн үйл ажиллагаа
                                            </option>
                                            <option value="87">
                                              Өрхийн өөрийн хэрэглээнд зориулан
                                              үйлдвэрлэсэн, хэмжээг нь
                                              тодорхойлох боломжгүй
                                              бүтээгдэхүүн, үйлчилгээ
                                            </option>
                                            <option value="88">
                                              Хувийн үйлчилгээний бусад үйл
                                              ажиллагаа
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-6">
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                      >
                                        Илгээх
                                      </button>
                                    </div>
                                    <div className="col-6">
                                      <button
                                        type="button"
                                        className="btn btn-secondary float-end"
                                        data-bs-dismiss="modal"
                                      >
                                        Хаах
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
